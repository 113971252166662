import { makeAutoObservable, reaction, runInAction } from 'mobx';
import {
  ExperimentalNoIndexDiff,
  ReadonlyJSONValue,
  Replicache,
} from 'replicache';
import { resolver } from './resolver';

export class BootstrapError extends Error {
  constructor() {
    super('Failed to bootstrap cache');
  }
}

export type ManagedCacheCallbacks = {
  onInitFound?: () => void;
  onInitNotFound?: () => void;
  onRecordAdded?: (key: string, value: ReadonlyJSONValue) => void;
  onRecordRemoved?: (key: string) => void;
  onRecordUpdated?: (
    key: string,
    oldValue: ReadonlyJSONValue,
    newValue: ReadonlyJSONValue
  ) => void;
  onUpdateNeeded?: () => void;
  getAuth?: () => void;
};

export class ManagedCache<R extends Replicache = Replicache<{}>> {
  public loadingState:
    | 'new'
    | 'bootstrapping' // no data, initial pull for client
    | 'bootstrapping-failed' // no data, initial pull for client failed
    | 'syncing' // we have data and are loading the latest
    | 'syncing-failed' // we have data but failed to load the latest
    | 'synced' = 'new'; // successfully pulled from server

  public updatedNeeded: 'unknown' | 'yes' | 'no' = 'unknown';
  public isPulling: boolean = false;
  public initialLoadFinished: boolean = false;

  private disposeWatch = () => {};

  constructor(
    public cacheId: string,
    public cache: R,
    private callbacks: ManagedCacheCallbacks = {}
  ) {
    console.log(this.cacheId, 'created cache');

    this.cache.onUpdateNeeded = () => {
      this.onUpdateNeeded();
    };

    this.cache.getAuth = () => {
      window.location.href = '/sign-in';
      return null;
    };

    this.cache.onSync = (syncing) => {
      console.log(this.cacheId, 'isPulling', syncing);
      runInAction(() => (this.isPulling = syncing));
    };

    makeAutoObservable(this);
  }

  public get readyToQuery() {
    return (
      this.initialLoadFinished &&
      (this.loadingState === 'syncing' ||
        this.loadingState === 'synced' ||
        this.loadingState === 'syncing-failed')
    );
  }

  public get failedToBootstrap() {
    return this.loadingState === 'bootstrapping-failed';
  }

  public get finishedSyncing() {
    return (
      this.loadingState === 'synced' ||
      this.loadingState === 'syncing-failed' ||
      this.loadingState === 'bootstrapping-failed'
    );
  }

  public async pull() {
    await this.cache.pull();
  }

  public async initializeCache() {
    if (
      this.loadingState === 'syncing' ||
      this.loadingState === 'bootstrapping'
    ) {
      console.warn(
        this.cacheId,
        'ignoring call to initializeCache since a pull is in progress'
      );
      return;
    }

    console.log(this.cacheId, 'initializing cache');

    try {
      const isInit = await this.cache.query<{}>((tx) => tx.get('init'));
      if (isInit) {
        console.log(this.cacheId, 'init key found, syncing');
        runInAction(() => (this.loadingState = 'syncing'));
      } else {
        console.log(this.cacheId, 'init key not found, bootstrapping');
        runInAction(() => (this.loadingState = 'bootstrapping'));
      }

      // if the server returns VersionNotSupported, replicache will call
      // onUpdatedNeeded before this returns
      if (!this.isPulling) {
        console.log(this.cacheId, 'pulling');
        await this.cache.pull({ now: true });
      } else {
        console.log(this.cacheId, 'waiting for existing pull to finish');
        await resolveWhenPresent(() => !this.isPulling, undefined);
        console.log(this.cacheId, 'pulling');
        await this.cache.pull({ now: true });
      }
      console.log(this.cacheId, 'pull finished');

      if (this.updatedNeeded === 'yes') {
        console.log(this.cacheId, 'updated needed, returning...');
        return;
      }
      runInAction(() => (this.updatedNeeded = 'no'));

      const isInitAfterPull = await this.cache.query<{}>((tx) =>
        tx.get('init')
      );
      if (isInitAfterPull) {
        console.log(this.cacheId, 'init key found after pull, synced');
        runInAction(() => (this.loadingState = 'synced'));
      } else {
        console.log(this.cacheId, 'init key not found after pull, failed');
        runInAction(() => (this.loadingState = 'bootstrapping-failed'));
      }
    } catch (e) {
      if (!this.cache.closed) {
        this.failLoad();
        console.error(this.cacheId, 'Error initializing cache:', e);
        throw e;
      }
    }
  }

  public close() {
    this.disposeWatch();
    this.cache.close();
    console.log(this.cacheId, 'closed');
  }

  private onUpdateNeeded() {
    this.updatedNeeded = 'yes';
    this.failLoad();
  }

  public startWatch(
    watchCallbacks: Pick<
      ManagedCacheCallbacks,
      'onRecordAdded' | 'onRecordRemoved' | 'onRecordUpdated'
    >
  ) {
    this.disposeWatch();

    this.disposeWatch = this.cache.experimentalWatch(
      (diffs) => {
        this.processDiffs(watchCallbacks, diffs);
      },
      {
        initialValuesInFirstDiff: true,
      }
    );
  }

  private processDiffs(
    watchCallbacks: Pick<
      ManagedCacheCallbacks,
      'onRecordAdded' | 'onRecordRemoved' | 'onRecordUpdated'
    >,
    diffs: ExperimentalNoIndexDiff
  ) {
    for (const diff of diffs) {
      if (diff.op === 'add') {
        watchCallbacks.onRecordAdded?.(diff.key, diff.newValue);
      } else if (diff.op === 'del') {
        watchCallbacks.onRecordRemoved?.(diff.key);
      } else if (diff.op === 'change') {
        watchCallbacks.onRecordUpdated?.(
          diff.key,
          diff.oldValue,
          diff.newValue
        );
      }
    }
    this.initialLoadFinished = true;
  }

  private failLoad() {
    if (this.loadingState === 'bootstrapping') {
      this.loadingState = 'bootstrapping-failed';
    } else if (this.loadingState === 'syncing') {
      this.loadingState = 'syncing-failed';
    }
  }
}

export function bootstrapManagedCache<MC extends ManagedCache>(
  managedCache: MC
) {
  const { promise, resolve, reject } = resolver<MC>();

  if (managedCache.readyToQuery) {
    resolve(managedCache);
    return promise;
  }

  const disposables: (() => void)[] = [];
  const cleanup = () => disposables.forEach((d) => d());

  disposables.push(
    reaction(
      () => managedCache.readyToQuery,
      (readyToQuery) => {
        if (readyToQuery) {
          resolve(managedCache);
          cleanup();
        }
      }
    )
  );

  disposables.push(
    reaction(
      () => managedCache.failedToBootstrap,
      (failedToBootstrap) => {
        if (failedToBootstrap) {
          reject(new BootstrapError());
          cleanup();
        }
      }
    )
  );

  managedCache.initializeCache();
  return promise;
}

export function resolveWhenPresent<T>(
  func: () => T,
  timeout: number | undefined = 1000
): Promise<NonNullable<T>> {
  const { promise, resolve, reject } = resolver<NonNullable<T>>();

  const initialValue = func();
  if (initialValue) {
    resolve(initialValue);
    return promise;
  }

  const timeoutId = timeout
    ? setTimeout(() => {
        reject(new Error('timed out'));
      }, timeout)
    : undefined;

  const dispose = reaction(
    () => func(),
    (value) => {
      if (!!value) {
        resolve(value);
        dispose();
        timeoutId && clearTimeout(timeoutId);
      }
    }
  );

  return promise;
}
